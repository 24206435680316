import SEO from "../components/seo"
import Simulador from '../components/pages/SimuladorCrediAlto'
import Layout from '../layouts/headerless'

const SimuladorPage = ({ pageContext }) => {
  return (
        <Layout companyName={pageContext.company} >
            <SEO title={pageContext.title} />
            <Simulador pageContext={pageContext}/>
        </Layout>
  )
}

export default SimuladorPage