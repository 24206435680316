import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import { ButtonDegrade } from '@findep/mf-landings-core'
import { navigate as navigateGatsby } from '../../helpers/queryNavigate';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Button } from '@material-ui/core';

function ButtonNavigate({ styleButton, variant, customizeButton, id, icon, disabled, textButton, onClick, nextPath, company, product, journey, navigate, fallbackUrl}) {
    const data = useStaticQuery(graphql`
    {
        allComponentsJson(filter: {component: {eq: "simulador-journey"}}) {
            edges {
                node {
                    id
                    routes {
                        company
                        product
                        journey
                        next {
                            path
                        }
                    }
                }
            }
        }
    }
    `)

    const _onClick = () => {
        const route = data.allComponentsJson.edges[0].node.routes.find(element => element.company === company && element.product === product && element.journey === journey)
        if (customizeButton) {
            navigateGatsby(fallbackUrl)
        } else {
            if(onClick) {
                onClick()
            }
            if(navigate) {
                navigateGatsby(nextPath || route?.next?.path || fallbackUrl)
            }
        }
    }

    return (
        customizeButton ? <Button disabled={disabled} onClick={_onClick} id={id} color='primary' style={styleButton} variant={variant} size="large"><b>{textButton}</b></Button>
        : <ButtonDegrade 
            id={id}
            icon={icon || ArrowForwardIcon} 
            disabled={disabled} 
            textButton={textButton}
            onClick={_onClick}
        />
    )
}

ButtonNavigate.propTypes = {
    id: PropTypes.string,
    icon: PropTypes.any,
    disabled: PropTypes.bool,
    textButton: PropTypes.string,
    onClick: PropTypes.func,
    nextPath: PropTypes.string,
    navigate: PropTypes.bool,
    company: PropTypes.string,
    product: PropTypes.string,
    journey: PropTypes.string,
    fallbackUrl:  PropTypes.string,
    customizeButton: PropTypes.bool,
    styleButton: PropTypes.any,
    variant: PropTypes.string,
}

ButtonNavigate.defaultProps = {
    navigate: true,
    customizeButton: false,
    // styleButton: { backgroundColor: '#0C9DA9', textColor: '#FFFFFF'  },
    variant: 'contained'
}

export default ButtonNavigate
