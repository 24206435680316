import { css } from '@emotion/react'
import { memo, useState } from 'react'
import PropTypes from 'prop-types'
import {
  useTheme
} from "@findep/microfronts-core"
import { useStaticQuery, graphql } from "gatsby"
import {
  MenuLayout,
  Header,
  CustomizedStepper,
} from '@findep/mf-landings-core'

import loadable from '@loadable/component'
import {
  Button, Menu, Typography,
} from '@material-ui/core';
import AefLogo from '../assets/svg/aef-logo.svg'
import FisaLogo from '../assets/svg/fisa/logo.svg'
import AfiLogo from '../assets/svg/afi-logo.svg'
import PrestaSumaLogo from '../assets/svg/prestasuma/prestasuma-logo.svg'
import CrediConstruyeLogo from '../assets/svg/prestasuma/crediconstruye-logo.svg'
import PrestaUnicoLogo from '../assets/svg/prestasuma/prestaunico-logo.svg'

import MainCard from '../components/MainCard'
import MainSection from '../components/MainSection'

import { AttachMoney, Home, AddComment, HowToReg as HowToRegIcon } from '@material-ui/icons';
import Footer from '../components/common/FooterFindep'
import ProvidersConfigLayout from './ProvidersConfig'

import Logo from '../components/img/Logo.compilable'
import GlobalFooterProvider from '../context/GlobalStateFooterContext'

// import Rastreo from '../components/forms/Rastreo'

const Rastreo = loadable(() => import('../components/forms/Rastreo'))

const MenuButton = ({ onClick, id }) => {
  const { palette } = useTheme()
  const contrastText = css`
    color: ${palette ? palette.primary.contrastText : 'white'} !important;
  `
  const topButtonCss = css`
    margin-right: 2px;
    margin-top: 2em;
    ${contrastText}
  `
  return (<Button id={id} css={topButtonCss} onClick={onClick} >MI SOLICITUD</Button>)

}

function FindepLayout({ broker, companyName, children, gradient, currentStep, iconos, menu, texts, appContext }) {
  const releaseCss = css`
    background-color: #eeedf0;
  `
  let RELEASE = process.env.GATSBY_RELEASE || '??????'
  const env = process.env.GATSBY_ACTIVE_ENV || '?'
  RELEASE = `${env.substr(0, 1).toUpperCase()}-${RELEASE}`
  const data = useStaticQuery(graphql`
  {
    allComponentsJson(filter: {component: {eq: "footer-app"}}) {
      edges {
        node {
          footer {
            terminosyavisos {
              link
              texto
            }
            terminosyavisosafileft {
              link
              texto
            }
            terminosyavisosafiright {
              link
              texto
            }
            condusefarray {
              texto
              src {
                publicURL
              }
              link
            }
            buroarray {
              extra
              texto
              src {
                publicURL
              }
              link
            }
            imagestext {
              extra
              texto
              link
              src {
                publicURL
              }
            }
            leyendas {
              onetext
              link
              twotext
              threetext
              fourtext
            }
            linkaef {
              link
              texto
            }
            linkfisa {
              link
              texto
            }
            linkone {
              link
              texto              
            }
            linktree {
              link
              texto              
            }
            linktwo {
              link
              texto
            }
            otros {
              texto
              src {
                publicURL
              }
              link
            }
            company
          }
        }
      }
    }
  }  
  `)
  const icons = iconos || { 1: <Home />, 2: <HowToRegIcon />, 3: <AddComment />, 4: <AttachMoney /> }
  const textos = texts || [{ texto: 'DATOS PERSONALES' }, { texto: 'VALIDACIÓN' }, { texto: 'DATOS ADICIONALES' }, { texto: 'OFERTA' }]

  const [modal, setModal] = useState(false)

  const getLogo = (company) => {

    return <Logo company={company} />
  }

  const toggleModal = () => {
    setModal(!modal)
  }

  return (
    <GlobalFooterProvider>
      <ProvidersConfigLayout appContext={appContext} companyName={companyName} >
          <MainCard>
            <MainSection>
              <Rastreo company={companyName} open={modal} onClose={() => setModal(false)} />
              {children}
              <div style={{width:'100vw'}}>
                <Footer broker={broker} companyName={companyName} data={data} />
              </div>
              <Typography css={releaseCss}>
                {RELEASE}
              </Typography>
            </MainSection>
          </MainCard>
      </ProvidersConfigLayout>
    </GlobalFooterProvider>
  )
}

FindepLayout.propTypes = {
  companyName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  currentStep: PropTypes.number,
  gradient: PropTypes.bool,
  menu: PropTypes.bool,
}

export default memo(FindepLayout)

